/* eslint-disable react/jsx-one-expression-per-line */
import React from "react"
import { graphql } from 'gatsby'

import Layout from '../../components/theme/Layout'
import Footer from '../../components/theme/Footer'
import Header from '../../components/theme/Header'
// import RenderBlock from '../../components/blocks/RenderBlock'
import BlueHeader from '../../components/common/BlueHeader'
import Container from '../../components/common/Container'
import Offers from '../../components/common/Offers'
import TextBlock from "../../components/blocks/TextBlock";

const PastWinnersPage = ({ data }) => (
  <Layout
    meta={data.defaultPage.meta}
    urls={data.defaultPage.urls}
    locale='en'
  >
    <Header
      urls={data.defaultPage.urls}
      locale='en'
    />

    <BlueHeader title={data.defaultPage.title} />

    <Container width={830}>
      {/* <RenderBlock blocks={data.defaultPage.blocks} /> */}
      <TextBlock text={`<h3>ROYALE<sup>&reg;</sup> &ldquo;Gear Up for Spring&rdquo; Contest 2020</h3>
      <p><strong>Grand Prize:</strong> The $5,500 prize package is:</p>
      <p>Ashley Shipway</p>
      <h3>ROYALE<sup>&reg;</sup> &ldquo;Gear Up for Spring&rdquo; Contest 2019</h3>
      <p><strong>Grand Prize:</strong> The grand prize winner &lsquo;$5,000 Spring Gear Pack&rsquo; is:</p>
      <p>Manda J, ON</p>
      <h3>ROYALE<sup>&reg;</sup> &ldquo;New Year New Room&rdquo; Contest</h3>
      <p><strong>Grand Prize:</strong> The grand prize winner &lsquo;$5,000 Gift Card&rsquo; is:</p>
      <p>Bonnie E, ON</p>
      <h3>ROYALE<sup>&reg;</sup> &ldquo;Fresh &amp; Clean&rdquo; Contest</h3>
      <p><strong>Grand Prize:</strong> The grand prize winner &lsquo;$5,000 in Spring Cleaning Gear&rsquo; is:</p>
      <p>Margaret G, ON</p>
      <h3>ROYALE<sup>&reg;</sup> &ldquo;Home for the Holidays&rdquo; Contest</h3>
      <p><strong>Grand Prize:</strong> The grand prize winner &lsquo;$5,000 and ROYALE<sup>&reg;</sup> Products for a Year&rsquo; is:</p>
      <p>Diana H-J, ON</p>`}
      />
    </Container>

    <Footer />
    <Offers />
  </Layout>
)

export const query = graphql`
query pastWinnersPage {
  defaultPage(guid: { eq: "5ebdf4cd-c7e3-4905-a0f5-9b8a5ef6e17c" }, locale: {eq:"en"}) {
    title
    subtitle
    description
    blocks {
      __typename
      ...textBlockFragment
    }
    urls {
      en
      fr
      zh_hant
      zh_hans
    }
    meta {
      description
      keywords
      title
    }
  }
}
`

export default PastWinnersPage
